import { getActions, addNewAction, deleteAction, updateAction, getActionThread, addNewComment, deleteComment, updateComment } from '@/Api/actions.js';


export default {
  namespaced: true,
  state () {
    return {
      actions: [],
      originalActions: [],
      action: {},
      comments_data: [],
    }
  },
  mutations: {
    actionsInfo (state, actions) {
      state.actions = actions
    },
    originalActionsInfo (state, originalActions) {
      state.originalActions = originalActions
    },
    updateThreadComments(state, newComment) {
      state.comments_data = [...state.comments_data, newComment];
    },
    actionInfo (state, action) {
      state.action = action
    },
    commentsInfo (state, comments_data) {
      state.comments_data = comments_data
    },
  },
  actions: {
    async getActions({ state, rootState, commit }) {
      let res = await getActions(rootState.auth.user.accessToken);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('actionsInfo', res.data);
      commit('originalActionsInfo', res.data);
    }, 

    async getActionThread({ state, rootState, commit }, actionPayload) {
      let res = await getActionThread(rootState.auth.user.accessToken, actionPayload);
      if(!res.status) {
        return false;
      }
      commit('actionInfo', res.data);
      commit('commentsInfo', res.data.comments);
    },

    async addAction({ state, rootState, commit }, actionPayload) {
      let res = await addNewAction(rootState.auth.user.accessToken, actionPayload);
      if(!res.status) {
        console.log("res.status faield", res.status);
        return false;
      }
      commit('actionsInfo', [...res.data, ...state.actions])
      return true;
    },

    async addComment({ state, rootState, commit }, commentPayload) {
      let res = await addNewComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("addNewComment faield", res.data);
        return false;
      }
      commit('updateThreadComments', res.data);
      return true;
    },

    async deleteAction({ state, rootState, commit }, actionPayload) {
      let res = await deleteAction(rootState.auth.user.accessToken, actionPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('actionsInfo', res.data);
    },

    async deleteComment({ state, rootState, commit }, commentPayload) {
      let res = await deleteComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('actionInfo', res.data);
      commit('commentsInfo', res.data.comments);
      return true;
    },

    async updateAction({ state, rootState, commit }, actionPayload) {
      let res = await updateAction(rootState.auth.user.accessToken, actionPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('actionsInfo', res.data);
      return true;
    },

    async updateComment({ state, rootState, commit }, commentPayload) {
      let res = await updateComment(rootState.auth.user.accessToken, commentPayload);
      if(!res.status) {
        console.log("faield", res.data);
        return false;
      }
      commit('commentsInfo', res.data);
      return true;
    },

    filterActionData({ state, rootState, commit }, search) {
      if (search.searchValue === '') {
          commit('actionsInfo', state.originalActions);
      } else {
        const filteredActions =  state.originalActions.filter(row => {
          // Check if any property of the row contains the search value
          return Object.values(row).some(value => {
                if (typeof value === 'object') {
                    // Check if the object contains the search value
                    const stringifiedValue = JSON.stringify(value)
                    return stringifiedValue.toLowerCase().includes(search.searchValue.toLowerCase())
                } else {
                    return String(value).toLowerCase().includes(search.searchValue.toLowerCase())
                }
          })
        })
        commit('actionsInfo', filteredActions);
      }
    }

  }
}
