import axios from './index.js'


export function getActions(idToken) {
  return axios.get('/action/getActions', {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
   }).catch((e) => {
     console.error(e.message); // "oh, no!"
     return e.message;
  });
}


export function getActionThread(idToken, actionPayload) {
  return axios.post('/action/getActionThread', actionPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
   }).catch((e) => {
     console.error(e.message); // "oh, no!"
     return e.message;
  });
}

export function addNewAction(idToken, newActionPayload) {
  return axios.post('/action/addAction', newActionPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}

export function deleteAction(idToken, ActionPayload) {
  return axios.post('/action/deleteAction',ActionPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}

export function addNewComment(idToken, newCommentPayload) {
  return axios.post('/action/addComment', newCommentPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
    }).catch((e) => {
      console.error(e.message);
      return e.message;
  });
}

export function deleteComment(idToken, CommentPayload) {
  return axios.post('/action/deleteComment', CommentPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
    }).catch((e) => {
      console.error(e.message);
      return e.message;
  });
}

export function updateComment(idToken, CommentPayload) {
  return axios.post('/action/updateComment', CommentPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
    }).catch((e) => {
      console.error(e.message);
      return e.message;
  });
}


export function updateAction(idToken, ActionPayload) {
  return axios.post('/action/updateAction', ActionPayload, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  }).then(comment => {
      return comment.data;
   }).catch((e) => {
     console.error(e.message);
     return e.message;
  });
}
